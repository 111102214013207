
import BoxDisplayRow from "../cell/box/BoxDisplayRow";
import RowDisplayRow from "../cell/row/RowDisplayRow";
import SpacerDisplayRow from "../cell/spacer/SpacerDisplayRow";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow";
import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import StringUtils from '@/utils/StringUtils.js';

export default class RcTableViewUtils {
    
  constructor(model, tableData) {
    this._model = model;
    this._tableData = tableData;
  }

  domain() {
    return this.model().domain();
  }
  
  model() {
    return this._model;
  }

  addHeader(row) {
    this._tableData.addHeaderRow(row);
    return this;
  }

  addBody(row) {
    return this.add(row);
  }
  
  add(row) {
    this._tableData.addRow(row);
    return this;
  }
  
  addFooter(row) {
    this._tableData.addFooterRow(row);
    return this;
  }

  start() {
    if (this.model().isStatePageLoading()) {
      this.addPageLoading();
    }
    if (this.model().isStateContentLoading()) {
      this.addContentLoading();
    }
  }

  addPageLoading() {
    this.add(new BoxDisplayRow()
      .withBorder(false)
      .withChild(new RowDisplayRow()
        .withChild(new SpacerDisplayRow().withWidth("5"))
        .withChild(new BoxDisplayRow().withBorder(true).withWidth("5").withChild(new LoadingDisplayRow().withMessage("").withKey("progress")))
        .withChild(new SpacerDisplayRow().withWidth("5"))
      )
    );
  }

  createPageInfo(totalItems, query) {
    const pageSize = this.model().pageSize(query);
    var end = this.model().pageNumber(query) * pageSize;
    const start = end - pageSize + 1;
    if (end > totalItems) {
      end = totalItems;
    }
    var display = StringUtils.format("{0} to {1} of {2}", [start, end, totalItems]);
    if (totalItems == 0) {
      display = "0";
    }
    return display;
  }

  renderError() {
    var box = new BoxDisplayRow().withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withChild(new SpacerDisplayRow().withWidth("5"))
        .withChild(new ErrorDisplayRow().withWidth("5").withError(this.model().error()))
        .withChild(new SpacerDisplayRow().withWidth("5"))
      );
    this.add(box);
  }

  addContentLoading() {
    this.addPageLoading();
  }

  userList() {
    var company = this.model().company();
    var employees = company.employees();
    var userMap = employees.users().find();
    var sorted =  userMap.sortByName();
    var list = [];
    for (var i = 0; i < sorted.length; i++) {
      var user = sorted[i];
      var userData = {};
      userData["@rid"] = user.id();
      userData["name"] = user.fullName() + " (" + user.email() + ")";
      if (StringUtils.startsWith(userData.name, "ReadyChek")) {
        continue;
      }
      if (StringUtils.isEmpty(user.fullName())) {
        continue;
      }
      list.push(userData)
    }
    return list;
  }
  
  vehicleList() {
    var company = this.model().company();
    var vehicles = company.vehicles();
    var sorted = vehicles.sortByName();
    var list = [];
    for (var i = 0; i < sorted.length; i++) {
      var item = sorted[i];
      var data = {};
      data["@rid"] = item.id();
      data["name"] = item.plate();
      if (StringUtils.isNotEmpty(item.name())) {
        data["name"] = data["name"] + " (" + item.name() + ")";
      }
      if (item.isTrailer()) {
        data["name"] = data["name"] + " [Trailer]";
      }
      list.push(data)
    }
    return list;
  }
}