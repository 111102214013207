<template>
  <Row>
  <Column :width="15">
  <transition name="rc-modal-fade">
    <div class="rc-modal-backdrop">
      <div class="rc-modal">
      
        <Box v-if="isSending">
          <TitleRow >
            {{ MC.Inspection.Email.Progress.Title.value() }}
          </TitleRow>
          <SpacerRow />
          <ValueRow  align="left">
            {{ MC.Inspection.Email.Progress.Message.value() }}
          </ValueRow>

          <SpacerRow />
          <SpacerRow />
          <SpacerRow />
        </Box>
        
        <Box v-else>
          
          <TitleRow >
            {{ MC.Inspection.Email.PageTitle.value() }}
          </TitleRow>
          <ErrorRow :error="error" /> 
          
          <SpacerRow />
          <Row>
            <Column>
              <center>
              <img width="25%" src="@/assets/2023/toolbar_messages_envelope.png" />
              </center>
            </Column>
          </Row>
          <SpacerRow />
          <Row>
            <Column :width="2"/>
            <Column :width="11">
            
              <ValueRow  align="left">
                {{ MC.Inspection.Email.MtoMessageStart.value() }}
              </ValueRow>
              
              <SpacerRow />
              
              <KeyEmail 
                :title="Fields.EMAIL" 
                :mandatory="true" 
                :required="missing"
                :placeholder="MC.Inspection.Email.Email.value()" 
                v-on:value="setValue" />
          
              <SpacerRow />
              <ValueRow  align="left">
                {{ MC.Inspection.Email.MtoMessageEnd.value() }}
              </ValueRow>
            </Column>
            <Column :width="2"/>
          </Row>
          <SpacerRow />
          <Row>
            <Column :width="3" />
            <Column :width="4">
              <Button v-on:click="back" padding="5px">Back</Button>
            </Column>
            <Column :width="1" />
            <Column :width="4">
              <Button :go="true" v-on:click="send" padding="5px">Send</Button>
            </Column>
            <Column :width="3" />
          </Row>
          <SpacerRow />
          
        </Box>
      </div>
    </div>
      
  </transition>
  </Column>
  </Row>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import MC from "@/domain/session/MC.js";

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";

import ErrorRow    from '@/components/row/ErrorRow.vue';
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import KeyEmail from '@/components/input/KeyEmail.vue';


export default {
  name: "email-dialog",
  components: {
    Box, Row, Column,
    SpacerRow, TitleRow,
    Button, ValueRow,
    ErrorRow, 
    KeyEmail,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    inspectionId: { type: String, default: "" },
  },
  data() {
    return {
      error: null,
      message: null,
      missing: false,
      
      isLoading: true,
      loadingMessage: null,
      MC: new MC(),
      Consts: ConstUtils,
      
      Strings: StringUtils,
      Contents: ContentUtils,
      
      Fields: {
        EMAIL: "email",
      },
      kvps: {},
      isSending: false,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    setValue: function (kvp) {
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
      this.error = null;
      this.message = null;
      this.missing = false;
    },
    isValueRequired: function(field) {
      this.missing === field;
    },
    areRequirementsMet: function() {
      this.missing = false;
      var met = ContentUtils.hasValue(this.kvps, this.Fields.EMAIL);
      if (!met) {
        this.missing = true;
        return false;
      }
      return met;
    },
    back: function() {
      this.isSending = false;
      this.$emit("close");
    },
    backTo: function() {
      this.isSending = false;
    },
    send: function() {
      if (!this.areRequirementsMet()) {
        return false;
      }
      this.error = null;
      this.missing = false;
      var id = this.itemId;
      if (StringUtils.isEmpty(id)) {
        id = this.inspectionId;
      }
      if (StringUtils.isEmpty(id)) {
        if (StringUtils.isEmpty(id)) {
          this.error = {
              hint: "Unable to determine which inspection to send",
              messageId: "NoInspectionFound"
          };
          return false;
        }
      }
      this.isSending = true;
      
      var email = this.kvps[this.Fields.EMAIL];

      var event = this.domain
          .events()
          .inspections().email(id, email);
      event.send(this.listener);
      return true;
    },
    
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        this.back();     
      }
      this.isSending = false;
    },
   },
}
</script>