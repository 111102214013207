import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import LabelDisplayRow from "@/portals/shared/cell//label/LabelDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
//import ErrorDisplayRow from "@/portals/shared/cell/error/ErrorDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
// import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderInspections();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderInspections() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Vehicle"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Trailers"))
      );

    var boxContent = new BoxDisplayRow()
    var even = true;
    //var nameFormat = "{0} ({1})";

    var list = this.model().inspections();
    for (var index = 0; index < list.length; index++) {
      var inspection = list[index];
      
      var inspector = inspection.inspector().find();
      var inspectorUser = inspector.user().find();
      var driver = inspection.driver().find();
      var driverUser = driver.user().find();
      var overallStatus = inspection.defectType();
      var detailsBox = new BoxDisplayRow();
      detailsBox
        .withWidth("5")
        .withChild(new LabelValueDisplayRow().withLabel("Date").withValue(inspection.createdDateDisplay()))
        .withChild(new StatusDisplayRow()
                        .withLabel(this.MC.Inspection.Details.StatusLabel.value())
                        .withValue(overallStatus))
        .withChild(new LabelValueDisplayRow().withLabel("Inspected By").withValue(inspectorUser.fullName()))
        .withChild(new LabelValueDisplayRow().withLabel("Driven By").withValue(driverUser.fullName()))
        .withChild(new LabelValueDisplayRow().withLabel("Trip Type").withValue(inspection.tripDisplay()))
        .done();

      if (this.model().isAdmin()) {
        const company = inspection.company().find();
        detailsBox
          .withChild(new LabelValueDisplayRow().withName("Company").withValue(company.name() + " (" + company.id() + ")"));
      }
  
      var vehicle = inspection.vehicle().find();
      var results = inspection.results().find();
      var vehicleResults = results.forVehicle(vehicle);
      var vehicleDefect = vehicleResults.mostSevereUnfixedDefectType();
      var vehicleBox = new BoxDisplayRow();
      var vehicleName = vehicle.name() + " (" + vehicle.plate() + ")";
      vehicleBox
        .withWidth("5")
        .withChild(new LabelValueDisplayRow().withLabel("Vehicle").withValue(vehicleName))
        .withChild(new StatusDisplayRow()
                              .withLabel(this.MC.Inspection.Details.StatusLabel.value())
                              .withValue(vehicleDefect))
        .withChild(new LabelValueDisplayRow().withLabel("Odometer").withValue(inspection.odometer()))
        .withChild(new SpacerDisplayRow())
        .withChild(new LabelValueDisplayRow().withLabel("Work Orders").withValue(""))
        .done();

      var trailerMap = inspection.trailers().find();
      var trailerList = trailerMap.sortByName();
      var trailersBox = new BoxDisplayRow();
      var trailersBoxRows = [];
      for (var trailerIndex = 0; trailerIndex < trailerList.length; trailerIndex++) {
        var trailerRows = [];
        var trailerBox = new BoxDisplayRow().withBorder(true);
        var trailer = trailerList[trailerIndex];
        var trailerResults = results.forVehicle(trailer);
        var trailerDefect = trailerResults.mostSevereUnfixedDefectType(true);
        var trailerName = trailer.name() + " (" + trailer.plate() + ")";
        var trailerAttached = trailerResults.isAttached();
        trailerRows.push(new LabelValueDisplayRow().withLabel("Trailer").withValue(trailerName));
        trailerRows.push(new StatusDisplayRow()
                              .withLabel(this.MC.Inspection.Details.StatusLabel.value())
                              .withValue(trailerDefect)
                            );
        trailerRows.push(new LabelValueDisplayRow().withLabel("Attached").withValue(trailerAttached ? "Yes" : "No"));
        trailerBox.withChildren(trailerRows).done();
        trailersBoxRows.push(trailerBox);
      }
      
      trailersBox
        .withWidth("5")
        .withBorder(false)
        .withChildren(trailersBoxRows)
        .done()

      var unfixedDefects = inspection.hasUnfixedDefects(true);
      var width = unfixedDefects ? "8" : "9";
      if (this.model().isAdmin()) {
        width = unfixedDefects ? "4" : "5";
      }

      var actionsRow = new RowDisplayRow()
        .withChild(new StringDisplayRow().withWidth(width))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Details").withId(inspection.id()).withField("Details"));

      if (this.model().isAdmin()) {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Delete").withId(inspection.id()).withField("Delete"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Rebuild PDF").withId(inspection.id()).withField("Rebuild"));
      }

      if (unfixedDefects) {
        actionsRow
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Repair").withId(inspection.id()).withField("Repair"));
      }

      actionsRow
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Print/Download").withId(inspection.id()).withField("Print"))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("Email").withId(inspection.id()).withField("Email"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("+ Remarks").withId(inspection.id()).withField("Remarks"));
      
      var buttonsBox = new BoxDisplayRow();
      buttonsBox.withChild(actionsRow);

      boxContent
        .withBorder(false)
        .withChild(
          new BoxDisplayRow()
            .withBorder(true)
            .withEven(even)
            .withChild(
              new RowDisplayRow()
                .withBorder(false)
                .withChild(detailsBox)
                .withChild(vehicleBox)
                .withChild(trailersBox))
            .withChild(buttonsBox)
          );
      even = !even;
    }
    box.withChild(boxContent).done();
    this.add(box);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("3").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("8"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    var user = this.model().user();
    var userId = user.id();
    if (user.isNew()) {
      userId = "";
    }
    var vehicle = this.model().vehicle();
    var vehicleId = vehicle.id();
    if (vehicle.isNew()) {
      vehicleId = "";
    }
    if (vehicleId) {
      //
    }
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Filters"))
          .withChild(new SpacerDisplayRow().withWidth("13"))
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new LabelDisplayRow().withWidth("1").withLabel("Status"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allStatus").withField("Status").withDefaultValue("all").withSelected(query["status"] === "all" || !query["status"]))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Good").withKey("goodStatus").withField("Status").withDefaultValue("good").withSelected(query["status"] === "good"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Minors").withKey("minorStatus").withField("Status").withDefaultValue("minor").withSelected(query["status"] === "minor"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Majors").withKey("majorStatus").withField("Status").withDefaultValue("major").withSelected(query["status"] === "major"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Any").withKey("anyStatus").withField("Status").withDefaultValue("any").withSelected(query["status"] === "any"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Repaired").withKey("repairedStatus").withField("Status").withDefaultValue("repaired").withSelected(query["status"] === "repaired"))
            )
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new LabelDisplayRow().withWidth("1").withLabel("Time"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("All").withKey("allTime").withField("Time").withDefaultValue("all").withSelected(query["time"] === "all"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Today").withKey("todayTime").withField("Time").withDefaultValue("today").withSelected(query["time"] === "today"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Yesterday").withKey("yesterdayTime").withField("Time").withDefaultValue("yesterday").withSelected(query["time"] === "yesterday"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Last 2 Weeks").withKey("last2WeeksTime").withField("Time").withDefaultValue("last2Weeks").withSelected(query["time"] === "last2Weeks"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("This Month").withKey("thisMonthTime").withField("Time").withDefaultValue("thisMonth").withSelected(query["time"] === "thisMonth"))
              .withChild(new ButtonDisplayRow().withWidth("2").withName("Last 30 days").withKey("last30DaysTime").withField("Time").withDefaultValue("last30Days").withSelected(query["time"] === "last30Days"  || !query["time"]))
              .withChild(new DateUpdateRow().withWidth("5").withName("Show Inspections On or Before").withKey("pickTime").withField("Date").withDefaultValue(isNaN(query["time"]) ? "" : query["time"] ))
            )
            .withChild(new RowDisplayRow().withBorder(true)
              .withChild(new BoxDisplayRow().withBorder(true).withWidth("7")
                .withChild(new StringListUpdateRow().withWidth("6").withName("User").withKey("pickUser").withField("User").withDefaultValue(userId).withOptions(this.userList()).withShowClear(true))
              )
              // .withChild(new BoxDisplayRow().withBorder(true).withWidth("8")
              //   .withChild(new StringListUpdateRow().withWidth("6").withName("Vehicle or Trailer").withKey("pickVehicle").withField("Vehicle").withDefaultValue(vehicleId).withOptions(this.vehicleList()).withShowClear(true))
              // )
            )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    // if (!user.isNew()) {
    //   var box = new BoxDisplayRow().withBorder(true);
    //   var title = StringUtils.format("Inspections for {0}", [user.fullName()]);
    //   box.withChild(new RowDisplayRow()
    //       .withBackgroundTitle()
    //       .withChild(new TitleDisplayRow().withWidth("15").withTitle(title))
    //     );
    //   this.add(box);
    // }
  }

}