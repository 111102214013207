<template>
  <DetailsDisplayView :inspectionId="inspectionId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></DetailsDisplayView>
</template>

<script>
import DetailsDisplayView from './DetailsDisplayView.vue';
export default {
  name: 'inspection-details-display-row',
  components: {
    DetailsDisplayView
  },
  props: {
    inspectionId: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
