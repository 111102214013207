<template>
  <WorkingDisplayView @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></WorkingDisplayView>
</template>

<script>
import WorkingDisplayView from './WorkingDisplayView.vue';
export default {
  name: 'working-display-view-row',
  components: {
    WorkingDisplayView
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
